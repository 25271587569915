.sidebar {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}

@media all and (min-width: 768px) {
  .sidebar {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
