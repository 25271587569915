.default {
  margin: 5rem;
  text-align: center;
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title > * {
  margin: 0 0.5rem;
}
