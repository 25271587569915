.default {
  border: 1px solid var(--color--gray-lighter);
  background-color: var(--color--gray-lightest);
  border-radius: var(--border-radius);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  margin: 0.6rem 0;
}

.heading {
  transition: background-color 0.2s, color 0.2s;
  padding: 1.3rem 2.2rem;
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
  align-items: center;
  background-color: var(--color--light);
  border-radius: var(--border-radius);
  min-height: 80px;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
}

.heading:focus {
  outline: none;
  border-color: var(--color--primary);
}

.expanded .heading {
  background-color: var(--color--dark);
  color: var(--color--light);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.assessment-name {
  display: flex;
  align-items: center;
  grid-column: 1 / 2;
}
.assessment-name h4 {
  margin: 0;
}
.assessment-name .icon {
  width: 34px;
}
.assessment-name svg {
  fill: currentColor;
}

.desc {
  display: none;
}
.desc p {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.5;
}

.center {
  justify-self: center;
}

.content {
  padding: 0 1.5rem 2rem;
  font-size: 1.2rem;
  line-height: 1.7;
}
.content p {
  margin: 0;
}

.contentMedia {
  margin-top: 2rem;
  text-align: center;
}
.contentMedia img {
  max-width: 100%;
}

.sectionHeading {
  margin-bottom: 0.5rem;
}

.ioc {
  display: none;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .heading {
    grid-template-columns: 4fr 1fr 8%;
  }

  .assessment-name {
    grid-column: 1 / 2;
  }
}

/* responsive: tablet-landscape */
@media all and (min-width: 1024px) {
  .heading {
    grid-template-columns: 45% 35% auto;
    grid-gap: 3rem;
  }

  .desc {
    display: block;
    grid-column: 2 / 3;
  }

  .warnings {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .content {
    display: grid;
    grid-template-columns: 270px auto;
    grid-gap: 30px;
  }
  .fullContent {
    grid-template-columns: 100%;
  }
}

/* print */
@media print {
  .default {
    background: none;
  }

  .heading,
  .expanded .heading {
    background: none;
    color: var(--color--black);
  }

  .ioc {
    display: block;
  }

  .heading {
    border-bottom: 1px solid var(--color--gray-lighter);
  }

  .icon {
    display: none;
  }
}
