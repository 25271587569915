.default {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about {
  font-size: 1.4rem;
  line-height: 1.4;
}

.contact-button {
  display: flex;
}
.contact-button a,
.contact-button button {
  margin-right: 1em;
}

.contact-info {
  display: none;
}

.main {
  page-break-before: always;
}

.results-section,
.killchain-section {
  position: relative;
}

.killchain-section canvas {
  max-width: 100%;
}

.isLoading {
  opacity: 0.1;
}

.resultsLoadingOverlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalText {
  margin: 3rem 0;
  line-height: 1.5;
}

.fab {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.spaced {
  line-height: 1.5;
}

.gauge {
  margin: 1em auto;
  max-width: 400px;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .default {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

/* responsive: tablet-landscape */
@media screen and (min-width: 1024px) {
  .sidebar {
    border: 1px solid var(--color--gray-lighter);
    background-color: var(--color--white);
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
    padding: 1.875rem 22%;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 60px;
    align-items: center;
  }
}

/* print */
@media print {
  .sidebarContainer {
    position: static;
    height: auto;
    overflow: auto;
  }

  .resultsSectionTest {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    border: 1px solid blue;
  }

  .sidebar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .results-section {
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .killchain-section {
    grid-column: 1 / 2;
    grid-row: 2;
  }

  .about-section {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }

  .contact-button {
    display: none;
  }

  .contact-info {
    display: block;
  }

  .contact-list {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 2rem 1rem;
    font-size: 1.2rem;
  }

  .contact-list dd {
    margin: 0;
  }

  .contact-label {
    text-transform: uppercase;
    font-weight: var(--weight--bold);
  }
}
