.toolTip {
  position: relative;
}

.tipText {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 30px;
  margin: 0 0 12px 0;
  padding: 5px 8px;
  background-color: var(--color--black);
  border-radius: var(--border-radius);
  font-size: 12px;
  color: var(--color--white);
  width: max-content;
  height: fit-content;
  max-width: 300px;
}
