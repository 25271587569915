.default {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
}
.default svg {
  fill: currentColor;
}
.default svg + h4 {
  margin-left: 1rem;
}

.text {
  composes: default;
  background: transparent;
  color: inherit;
  font-weight: inherit;
  border: none;
  padding: 0;
}
.text:focus {
  outline: none;
  text-decoration: underline;
}

.primary {
  composes: default;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background-color: var(--color--primary);
  color: var(--color--light);
  text-transform: uppercase;
  border-radius: var(--border-radius);
  border: 1px solid var(--color--primary);
  padding: 1rem 1.5rem;
  font-size: 1.6rem;
  font-weight: var(--weight--bold);
  font-family: var(--font-family-heading);
}
.primary:hover {
  cursor: pointer;
}
.primary::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: var(--color--primary-dark);
  transform-origin: center;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.25s ease-in-out;
}
.primary:hover::before {
  transform: translate(-50%, -50%) scale(15);
}

.fab {
  composes: primary;
  border-radius: 50%;
  font-size: 2.4rem;
  width: 5rem;
  height: 5rem;
  padding: 0;
  justify-content: center;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
}

.icon {
  order: 1;
  margin-right: 1rem;
}
.right .icon {
  order: 2;
  margin-right: 0;
  margin-left: 1rem;
}

.content {
  order: 1;
}

.default:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
