.default {
  margin-top: 8rem;
  background: #1c1d22 url(../../assets/footer-bg.png) no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 5rem 0;
  color: var(--color--light);
}

.colophon {
  max-width: calc(var(--container-width) / 1.8);
  margin: 0 auto;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.5;
}

.fine {
  border-top: 1px solid rgba(30, 136, 229, 0.6);
  margin-top: 4rem;
  padding-top: 4rem;
  font-size: 1.2rem;
}
.fine p {
  margin: 0;
}

.links {
  margin: 2rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.links a {
  color: var(--color--light);
  text-decoration: none;
}
.links a:hover {
  text-decoration: underline;
}

.item {
  line-height: 1;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid var(--color--light);
}
.item:first-child {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .fine {
    display: flex;
    justify-content: space-between;
  }

  .links {
    margin: 0;
  }
}

/* print */
@media print {
  .default {
    padding: 0;
    margin: 1rem 0 4rem;
    background: none;
    color: var(--color--black);
  }

  .colophon {
    display: none;
  }

  .fine {
    display: block;
    text-align: center;
    border-top: none;
  }
}
