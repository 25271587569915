.formTerms {
  overflow: hidden;
  background-color: var(--color--light);
  padding: 2rem;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}
.formTerms > :first-child {
  margin-top: 0;
}
.formTerms > :last-child {
  margin-bottom: 0;
}
.formTerms h4 {
  font-weight: var(--weight--bold);
  font-size: 1.2rem;
}

.formField {
  margin: 2rem 0;
}

.formLabel {
  display: block;
  margin-bottom: 1rem;
  font-weight: var(--weight--bold);
}

.formInput {
  width: 100%;
  padding: 1rem 0.5rem;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid var(--color--gray);
}
.formInput:focus {
  outline: none;
  border-bottom-color: var(--color--primary);
}

.formActions {
  margin-top: 3rem;
}

.formSubmitted {
  text-align: center;
}

.formError {
  --color--error: #f56565;
  background-color: #fff5f5;
  padding: 1em;
  margin: 2em 0;
  font-size: 1.2rem;
  border: 1px solid var(--color--error);
  color: var(--color--error);
  border-radius: var(--border-radius);
  line-height: 1.5;
}
