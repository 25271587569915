.default {
  max-width: 800px;
  margin: 2rem 1rem;
  line-height: 1.6;
}

.disclaimer {
  border: 1px solid var(--color--gray-lighter);
  background-color: var(--color--light);
  border-radius: var(--border-radius);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  padding: 1rem 3rem;
  margin-bottom: 5rem;
  font-size: 1.4rem;
}

.ctas {
  text-align: center;
}
.ctas p {
  margin-bottom: 4rem;
}

@media all and (min-width: 600px) {
  .default {
    margin: 7rem auto 0;
  }
}
