.default {
  background-color: var(--color--dark);
  color: var(--color--light);
  padding: 3rem 0;
}

.wrap {
  display: flex;
  justify-content: center;
}

.bar {
  background-color: var(--color--light);
  width: 40%;
  height: 1.4rem;
  position: relative;
  border-radius: 0.7rem;
  margin-right: 2rem;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: var(--color--secondary);
  height: 100%;
  width: 0;
  border-radius: 0.7rem;
}

.running .progress {
  animation: pulse 1.2s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.percent {
  min-width: 4rem;
}

.caption {
  margin-top: 0;
  text-align: center;
  font-size: 1.4rem;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .caption {
    font-size: 1.6rem;
  }
}

/* print */
@media print {
  .default {
    display: none;
  }
}
