.default {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  position: relative;
  margin: 1.4rem 0 0 3rem;
  line-height: 1.4;
}

.icon {
  position: absolute;
  top: -0.5rem;
  left: -3rem;
}

.small .icon {
  left: -2.5rem;
}
