.default {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2.4rem;
  height: 2.4rem;
}
.default svg {
  fill: currentColor;
  max-height: 60%;
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.25px;
}

.wrapPill {
  align-items: center;
  display: flex;
  height: 100%;
  margin-right: 0.35rem;
}

.text {
  font-size: 1.6rem;
}

.pillDefault {
  align-items: center;
  border-radius: 2rem;
  display: inline-flex;
  height: 3.8rem;
  padding: 0.75rem 1.5rem;
  position: relative;
  width: initial;
}
.pillDefault svg {
  fill: currentColor;
  height: 100%;
  max-height: initial !important;
}

.bubble {
  border-radius: 50%;
}
.bubble svg {
  width: 40%;
}

.high {
  composes: default;
}
.high.bubble,
.high.pill {
  background-color: var(--color--danger);
  color: var(--color--light);
}

.low {
  composes: default;
}
.low.bubble,
.low.pill {
  background-color: var(--color--unsuccessful);
  color: var(--color--white);
}
.low svg {
  max-height: 60%;
}

.warning {
  composes: default;
  color: var(--color--warning);
}
.warning svg {
  width: 100% !important;
  max-height: none !important;
}

.check {
  composes: default;
  color: var(--color--check);
}
.check svg {
  width: 100%;
}

.small {
  width: 1.5rem;
  height: 1.5rem;
}
.small svg {
  width: 100%;
}
.small.bubble svg {
  width: 50%;
}

.medium {
  composes: default;
  width: 3.1rem;
  height: 3.1rem;
}

.large {
  composes: default;
  width: 4rem;
  height: 4rem;
}

.pill {
  composes: pillDefault;
}
