.default {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  width: 90%;
  max-width: 720px;
  background-color: var(--color--gray-lightest);
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.2), 0px 5px 22px rgba(0, 0, 0, 0.12),
    0px 12px 17px rgba(0, 0, 0, 0.14);
  padding: 4rem 4rem 6.6rem;
  position: relative;
}

.content > :first-child {
  margin-top: 0;
}

.content > h2 {
  margin: 0 0 3.6rem;
}

.content > p {
  margin: 0;
  font-size: 1.4rem;
  color: var(--color--gray);
  font-weight: var(--weight--light);
}

.content > p:not(:last-of-type) {
  margin: 0 0 1.5rem;
}

.smallWidth {
  max-width: 520px;
  padding: 2.4rem 2.4rem 4.8rem;
}

.okCloseButton {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  color: var(--color--primary);
  font-size: 1.4rem;
  font-weight: var(--weight--semibold);
}

.smallWidth > .okCloseButton {
  right: 1.6rem;
  bottom: 1.8rem;
}

.xCloseButton {
  position: absolute;
  top: 3rem;
  right: 3rem;
}

.smallWidth > .xCloseButton {
  top: 1.8rem;
  right: 1.6rem;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .content {
    width: 70%;
  }
}

/* responsive: tablet-landscape */
@media all and (min-width: 1024px) {
  .content {
    width: 50%;
  }
}
