.default {
  border: 1px solid var(--color--gray-lighter);
  background-color: var(--color--white);
  border-radius: var(--border-radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
  margin: 0.6rem 0;
  padding: 1.875rem;
}

.heading {
  margin-top: 0;
}

.desc {
  margin-top: 1em;
  font-style: italic;
}

.tooltip {
  display: flex;
  justify-content: space-between;
}
