:root {
  /* colors */
  --color--light: #ffffff;
  --color--gray-lightest: #f3f3f3;
  --color--gray-lighter: #d4d4d4;
  --color--gray-light: #858585;
  --color--gray: #555555;

  --color--white: #ffffff;
  --color--black: #0a2240;
  --color--check: #43a047;
  --color--danger: #c62828;
  --color--dark: #0e203d;
  --color--primary: #2e7cd9;
  --color--primary-dark: #2561aa;
  --color--secondary: #ea0029;
  --color--success: #c0ca33;
  --color--success-dark: #5d5f47;
  --color--unsuccessful: #aaaaaa;
  --color--warning: #fb8c00;

  /* spacing and sizing */
  --container-width: 1440px;
  --tablet-width: 600px;

  /* font */
  --font-family-sans: 'Roboto', sans-serif;
  --font-family-heading: 'Titillium Web', sans-serif;
  --font-family-tag: 'Roboto Condensed', sans-serif;
  --weight--light: 300;
  --weight--normal: 400;
  --weight--semibold: 600;
  --weight--bold: 700;

  /* border */
  --border-radius: 4px;
}

@import '~normalize.css';
@import '~typeface-titillium-web';
@import '~typeface-roboto';
@import '~typeface-roboto-condensed';
@import './transitions.css';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  min-height: 100%;
}

body {
  font-family: var(--font-family-sans);
  font-size: 1.6rem;
  font-weight: var(--weight--normal);
  background-color: var(--color--light);
  background: linear-gradient(var(--color--gray-lightest), var(--color--light));
  background-size: 100% 100vh;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

/* print */
@media print {
  body {
    background: none;
  }
}
