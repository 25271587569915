.fade {
  opacity: 0;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active,
.fade-enter-done {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-exit-done {
  display: none;
}

.slider {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media print {
  .slider {
    display: block !important;
    height: auto !important;
  }
}
