.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-heading);
}

.h2 {
  font-size: 2.4rem;
  font-weight: var(--weight--semibold);
}

.h3 {
  font-size: 1.8rem;
  font-weight: var(--weight--semibold);
}

.h4 {
  font-weight: var(--weight--semibold);
}

.h5 {
  font-size: 1.2rem;
  font-weight: var(--weight--bold);
  font-family: var(--font-family-sans);
  text-transform: uppercase;
}
