.header {
  position: relative;
  background-color: var(--color--light);
  padding: 2rem 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
  /* Should display over modal overlays which have a z-index of 1000 */
  z-index: 1100;
}

.logo {
  margin: 1.5rem 0 0;
  text-decoration: none;
  color: var(--color--black);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.logo-wrap {
  margin: 0;
  width: 100px;
  margin-left: 2rem;
}

.logo-image {
  width: 100%;
}

.title {
  text-align: center;
  margin: 0;
  font-style: italic;
  font-weight: var(--weight--bold);
  font-size: 2.5rem;
  color: var(--color--black);
}

.powered {
  font-style: italic;
}

/* responsive: tablet */
@media all and (min-width: 600px) {
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    margin: 0;
    font-size: 1.6rem;
  }

  .logo-wrap {
    width: 160px;
  }

  .title {
    font-size: 3.6rem;
  }
}
